<template>
  <div>
    <div
      class="mb-3"
      style="width: 100%"
      v-loading="loading"
      element-loading-text="Loading..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-row :gutter="24">
        <el-col :xs="24" :sm="24" :md="12" :lg="15" :xl="15" :offset="3">
          <el-steps
            :active="activeStep"
            :finish-status="finishStatus"
            simple
            style="margin-top: 20px"
          >
            <el-step
              title="Choose Plans"
              :status="step1"
              @click.native="builderStep(1)"
              style="cursor: pointer"
            ></el-step>
            <el-step
              title="Plan Details"
              :status="step2"
              @click.native="builderStep(2)"
              style="cursor: pointer"
            ></el-step>
            <el-step
              title="Payment"
              :status="step3"
              @click.native="builderStep(3)"
              style="cursor: pointer"
            ></el-step>
          </el-steps>
        </el-col>
      </el-row>

      <template v-if="activeStep === 1">
        <div class="row gx-3 mt-1">
          <div
            class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4"
            v-for="(plan, index) in getPlansData"
            :key="index"
          >
            <div
              class="card shadow-sm h-100"
              :class="{ 'active-card': plan.isActive }"
              style="height: 60vh"
            >
              <div
                class="card-header text-center"
                :style="getStyle(plan.productInfo.plan_name)"
              >
                <h3 class="mb-0">{{ plan.productInfo.plan_name }}</h3>
                <h5 class="mt-2">
                  {{ plan.productInfo.price }}
                  <span>{{ plan.productInfo.price_currency }}</span>
                  /{{ plan.productInfo.bill_every }}
                  {{ plan.productInfo.bill_every_1 }}
                </h5>
              </div>
              <div class="card-body d-flex flex-column">
                <div class="feature-list flex-grow-1 overflow-auto">
                  <ul class="list-unstyled mb-3">
                    <li
                      v-for="(o, index) in plan.productInfo.features"
                      :key="index"
                      class="d-flex align-items-center mb-1"
                    >
                      <svg
                        class="me-2 text-success"
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                      >
                        <path
                          fill="currentColor"
                          d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768zm0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896z"
                        ></path>
                        <path
                          fill="currentColor"
                          d="M745.344 361.344a32 32 0 0 1 45.312 45.312l-288 288a32 32 0 0 1-45.312 0l-160-160a32 32 0 1 1 45.312-45.312L480 626.752l265.344-265.408z"
                        ></path>
                      </svg>
                      {{ o }}
                    </li>
                  </ul>
                </div>
                <div class="sticky-footer">
                  <button class="btn btn-light w-100" @click="subscribe(plan)">
                    Choose Plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeStep === 2">
        <br />
        <el-row :gutter="12">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" :offset="5">
            <el-card class="box-card">
              <div slot="header">
                <h3 class="align-center">
                  {{ selectedPlan.plan.productInfo.plan_name }}
                </h3>
              </div>
              <div>
                <p>Number of Licenses : <strong>1 License </strong></p>
              </div>
              <el-divider />
              <div id="featuresList">
                <strong>Features :</strong><br />

                <ul>
                  <li
                    v-for="(o, index) in selectedPlan.plan &&
                    selectedPlan.plan.productInfo &&
                    selectedPlan.plan.productInfo.features"
                    :key="o + '_' + index"
                    class="text item productInfo inlineList"
                  >
                    <span v-if="index < 5">
                      <svg
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg"
                        data-v-029747aa=""
                      >
                        <path
                          fill="currentColor"
                          d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768zm0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896z"
                        ></path>
                        <path
                          fill="currentColor"
                          d="M745.344 361.344a32 32 0 0 1 45.312 45.312l-288 288a32 32 0 0 1-45.312 0l-160-160a32 32 0 1 1 45.312-45.312L480 626.752l265.344-265.408z"
                        ></path>
                      </svg>
                      &nbsp;&nbsp;{{ o }}
                    </span>
                  </li>
                </ul>
                <el-popover
                  placement="bottom-start"
                  title="Features"
                  width="400"
                  trigger="hover"
                  :content="selectedPlan.plan.productInfo.features.toString()"
                  v-if="
                    selectedPlan.plan &&
                    selectedPlan.plan.productInfo &&
                    selectedPlan.plan.productInfo.features &&
                    selectedPlan.plan.productInfo.features.length > 5
                  "
                >
                  <el-link type="info" slot="reference">View more</el-link>
                </el-popover>
              </div>
              <el-divider />
              <!-- <div class="custom-control custom-radio">
                                <input type="radio" id="monthly" name="monthly" class="custom-control-input"
                                    v-model="selectedPlan.term" value="1" @change="changeTerm" />
                                <label class="custom-control-label" for="monthly">Pay Monthly</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" id="yearly" name="yearly" class="custom-control-input"
                                    v-model="selectedPlan.term" value="12" @change="changeTerm" />
                                <label class="custom-control-label" for="yearly">Pay Annually</label>
                            </div>
                            <el-divider /> -->
              <table class="table">
                <tr>
                  <td class="pull-left">
                    Subscription for
                    {{ selectedPlan.plan.productInfo.bill_every }}
                    {{ selectedPlan.plan.productInfo.bill_every_1 }}
                  </td>
                  <td class="pull-right strong">
                    {{ selectedPlan.plan.amount }}
                  </td>
                </tr>
                <tr>
                  <td class="pull-left">Sub Total</td>
                  <td class="pull-right strong">
                    {{ selectedPlan.plan.productInfo.price_currency }}
                    {{ selectedPlan.plan.amount }}
                  </td>
                </tr>
                <tr>
                  <td class="pull-left">Tax</td>
                  <td class="pull-right strong">
                    {{ selectedPlan.plan.productInfo.price_currency }}
                    {{ selectedPlan.plan.tax }}
                  </td>
                </tr>
              </table>
              <!-- <el-divider />
                            <el-button type="text">Add Promo code</el-button> -->
              <!-- <el-divider /> -->
              <!-- <table class="table">
                               
                            </table> -->
              <el-divider />
              <table class="table">
                <tr>
                  <td class="pull-left strong">Total</td>
                  <td class="pull-right">
                    {{ selectedPlan.plan.productInfo.price_currency }}
                    {{ selectedPlan.plan.totalAmount }}
                  </td>
                </tr>
              </table>
              <div class="align-center">
                <el-button type="danger" @click="orderPlaced"
                  >Proceed to Pay (
                  {{ selectedPlan.plan.productInfo.price_currency }}
                  {{ selectedPlan.plan.totalAmount }} )</el-button
                >
              </div>
            </el-card>
          </el-col>
        </el-row>
      </template>
      <template v-if="activeStep === 3">
        <br />
        <el-row :gutter="12">
          <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" :offset="5">
            <el-card class="box-card">
              <div slot="header">
                <h3 class="align-center">Payment</h3>
              </div>
              <div v-if="pgDetails">
                <el-row :gutter="12">
                  <el-col
                    :span="8"
                    v-for="(system, index) in activeSystemsForSubScription"
                    :key="index"
                  >
                    <span>
                      <el-card
                        class="box-card ml-1 mt-1"
                        style="cursor: pointer; border: 1px solid"
                        @click.native="paymentInit(system)"
                      >
                        <div style="width: 80%; height: 3rem">
                          <img
                            class="image-fit"
                            :src="system.logo"
                            :alt="system.pgType"
                            style="width: 100%"
                          />
                          <!-- {{ system.pgType }} -->
                        </div>
                      </el-card>
                    </span>
                  </el-col>
                </el-row>
              </div>
              <div v-else>Payment systems not available at this moment.</div>
            </el-card>
          </el-col>
        </el-row>
      </template>
      <div class="clearfix"></div>

      <el-dialog
        title="Payment"
        :visible.sync="dialogVisible"
        width="50%"
        :before-close="handleClose"
      >
        <!-- @cancelHandle="handleClose" -->
        <StripeCheckout
          :pk="pk"
          :currency="selectedCurrency"
          :customer="customer"
          :description="subscriptionData"
          :redirectionPath="redirectionPath"
          :microservicePath="microservicePath"
          :pgAccountId="pgAccountId"
          :payAmount="items.amount"
          :txnAcId="txnAcId"
        />
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import StripeCheckout from "../payment/payment.vue";
import AppDetails from "../../config/app";
import { pay } from "../../utils/swirepay/index";
// import _ from "lodash";
export default {
  name: "SubscriptionInformation",
  components: {
    StripeCheckout,
  },
  data() {
    return {
      finishStatus: "success",
      step1: "process",
      step2: "wait",
      step3: "wait",
      pk: "",
      pgAccountId: "",
      txnAcId: "",
      redirectionPath: "",
      microservicePath: "",
      items: [],
      activeStep: 1,
      individualPlan: {},
      businessPlan: {},
      businessProfessional: {},
      businessEssential: {},

      currentCardBackground: Math.floor(Math.random() * 25 + 1), // just for fun :D
      cardName: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvv: "",
      minCardYear: new Date().getFullYear(),
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,

      loading: false,
      stripeApiDialog: false,
      paymentInfo: {},
      ruleForm: {
        name: "",
        email: "",
        address: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Please input your Name",
            trigger: "blur",
          },
          {
            min: 5,
            max: 25,
            message: "Length should be 5 to 25",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Please input your email",
            trigger: "blur",
          },
          //   { min: 5, max: 25, message: 'Length should be 5 to 25', trigger: 'blur' }
        ],
        address: [
          {
            required: true,
            message: "Please input activity form",
            trigger: "blur",
          },
        ],
      },
      selectedPlan: { term: 1, plan: {}, salesTax: 0 },
      directPaymentTrue: false,
      dialogVisible: false,
      subscriptionData: {},
      selectedPaymentSystem: {},
      selectedCurrency: "USD",
      customer: { email: "", name: "", phoneNumber: "" },
      activeSystemsForSubScription: [],
    };
  },
  computed: {
    ...mapGetters("paymentGateway", [
      "getPlansData",
      "getActivePaymentSystems",
      "getPaymentGatewayInfo",
      "getUpdateSystemInfo",
      "getPayment",
      "getOrderInfo",
      "getPGDetails",
    ]),
    ...mapGetters("auth", ["getActiveWorkspace", "getAuthenticatedUser"]),
    pgDetails() {
      if (
        this.getPGDetails &&
        this.getPGDetails.cred_details &&
        this.getPGDetails.cred_details.length
      ) {
        return true;
      } else {
        return false;
      }
    },
    getLogo() {
      return (key) => {
        this.getActivePaymentSystems.filter(async (system) => {
          if (system.code === key) {
            return system.logo;
          }
        });
      };
      //
    },
    getCardType() {
      let number = this.cardNumber;
      let re = new RegExp("^4");
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      re = new RegExp("^9792");
      if (number.match(re) != null) return "troy";

      return "visa"; // default type
    },
    generateCardNumberMask() {
      return this.getCardType === "amex"
        ? this.amexCardMask
        : this.otherCardMask;
    },
    minCardMonth() {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    },
  },
  created() {},
  mounted() {
    this.pgAccountId = AppDetails.SUBSCRIPTION_PGID;
    this.redirectionPath =
      AppDetails.PAYMENT_REDIRECTION_URL +
      "/payments-status?pgid=" +
      this.pgAccountId;
    this.microservicePath = AppDetails.PAYMENT_GATEWAY_URL;

    this.getEsignsPlansData();
    this.cardNumberTemp = this.otherCardMask;
    // this.getActivePaymentSystemsApi();
    this.fetchInitSystemsDataByPgId();
  },
  watch: {
    cardYear() {
      if (this.cardMonth < this.minCardMonth) {
        this.cardMonth = "";
      }
    },
  },
  methods: {
    getStyle(productName) {
      // Trim and normalize spaces for comparison
      const normalizedProductName = productName.trim();
      if (normalizedProductName === "Premium Business Plan") {
        return `background:blueviolet;color:white`;
      } else if (normalizedProductName === "Only eSigns") {
        return `background:#f754a2;color:white`;
      } else if (normalizedProductName === "Only Kodefast") {
        return `background:#4b0081;color:white`;
      } else {
        return `background:darkcyan;color:white`;
      }
    },
    changeTerm() {
      this.selectedPlan.plan.amount =
        this.selectedPlan.plan.productInfo.price * this.selectedPlan.term;
      this.selectedPlan.plan.amount = (
        Math.round(this.selectedPlan.plan.amount * 100) / 100
      ).toFixed(2);
      this.selectedPlan.plan.tax =
        this.selectedPlan.plan.productInfo.price *
        this.selectedPlan.term *
        this.selectedPlan.salesTax;
      this.selectedPlan.plan.tax = (
        Math.round(this.selectedPlan.plan.tax * 100) / 100
      ).toFixed(2);
      this.selectedPlan.plan.totalAmount =
        parseFloat(this.selectedPlan.plan.amount) +
        parseFloat(this.selectedPlan.plan.tax);
      this.selectedPlan.plan.totalAmount = (
        Math.round(this.selectedPlan.plan.totalAmount * 100) / 100
      ).toFixed(2);
    },
    async fetchInitSystemsDataByPgId() {
      await this.$store.dispatch("paymentGateway/fetchActivePaymentSystems");
      await this.$store.dispatch(
        "paymentGateway/fetchInitSystemsDataByPgId",
        AppDetails.SUBSCRIPTION_PGID
      );
      if (
        this.getActivePaymentSystems &&
        this.getPGDetails &&
        this.getPGDetails.cred_details
      ) {
        let systemsList = this.getActivePaymentSystems;
        this.activeSystemsForSubScription = (
          this.getPGDetails.cred_details || []
        ).filter((pgData) => {
          if (AppDetails.WORKING_PAY_SYSTEMS.includes(pgData.pgType)) {
            let logo = systemsList.find((o) => o.code == pgData.pgType);
            pgData.logo = logo.logo ? logo.logo : "";
            return pgData;
          }
        });
        // this.activeSystemsForSubScription = await _.filter(this.getPGDetails.cred_details, function (pgData) {
        //     if (_.includes(AppDetails.WORKING_PAY_SYSTEMS, pgData.pgType)) {
        //         let logo = _.find(systemsList, function (o) { return o.code == pgData.pgType; });
        //         pgData.logo = logo.logo ? logo.logo : '';
        //         return pgData;
        //     }
        // });
      }
    },
    async orderPlaced() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.step1 = "success";
        this.step2 = "success";
        this.ste3 = "process";
        this.activeStep = this.activeStep + 1;
      }, 3000);
    },
    handleClose(done) {
      this.$confirm("Are you sure to cancel the payment?")
        .then((success) => {
          console.log(success);
          done();
        })
        .catch((fail) => {
          console.log(fail);
        });
    },
    directPaymentTrueCheck() {
      this.directPaymentTrue = !this.directPaymentTrue;
    },
    async getEsignsPlansData() {
      this.loading = true;
      let productId = AppDetails.ESIGNS_PRODUCT_ID;
      await this.$store.dispatch("paymentGateway/fetchPlansData", {
        productId: productId,
        planType: this.getAuthenticatedUser.current_workspace.plan_type,
      });
      this.loading = false;
    },
    // async getActivePaymentSystemsApi() {
    //     this.loading = true;
    //     await this.$store.dispatch("paymentGateway/fetchActivePaymentSystems");
    //     // "active systems", this.getActivePaymentSystems);
    //     this.loading = false;
    // },
    async paymentInit(info) {
      this.loading = true;
      this.items = {
        id: this.selectedPlan.plan.productInfo.plan_name,
        amount: this.selectedPlan.plan.totalAmount,
      };
      this.customer.email =
        this.getAuthenticatedUser && this.getAuthenticatedUser.email;
      this.customer.name =
        this.getAuthenticatedUser && this.getAuthenticatedUser.first_name
          ? this.getAuthenticatedUser &&
            this.getAuthenticatedUser.first_name +
              " " +
              this.getAuthenticatedUser.last_name
          : "";
      this.customer.phoneNumber =
        this.getAuthenticatedUser && this.getAuthenticatedUser.phone;

      if (this.customer.phoneNumber) {
        this.customer.phoneNumber = "+91" + this.customer.phoneNumber;
      }

      this.selectedPlan.email = this.getAuthenticatedUser.email;
      await this.$store.dispatch(
        "paymentGateway/placeOrder",
        this.selectedPlan
      );

      if (this.getOrderInfo) {
        let bytes = await this.$CryptoJS.AES.decrypt(info.crypto, info.ivKey);
        let decryptedData = JSON.parse(bytes.toString(this.$CryptoJS.enc.Utf8));
        this.pk = decryptedData.publicKey;
        this.selectedPaymentSystem = info;

        let tax =
          this.getOrderInfo.plan &&
          this.getOrderInfo.plan.productInfo &&
          this.getOrderInfo.plan.productInfo.tax_
            ? this.getOrderInfo.plan.productInfo.tax_
            : 0;
        let desc = {
          template_data: {
            payment_id: "ESIGNS-" + Date.now(),
            invoice: this.getOrderInfo.invoiceInfo._id,
            customer: this.getOrderInfo.customerInfo.entity_data_id,
            amount_to_be_paid:
              this.getOrderInfo.invoiceInfo.templateInfo.template_data
                .total_price,
            amount_paid_currency:
              this.getOrderInfo.invoiceInfo.templateInfo.template_data
                .total_price_currency,
            balance_currency:
              this.getOrderInfo.invoiceInfo.templateInfo.template_data
                .total_price_currency,
            "invoice/name":
              this.getOrderInfo.invoiceInfo.templateInfo.template_data
                .invoice_id,
            "customer/name":
              this.getOrderInfo.invoiceInfo.templateInfo.template_data[
                "customer/name"
              ],
            amount_paid:
              this.getOrderInfo.invoiceInfo.templateInfo.template_data
                .total_price,
            balance: 0,
            // "net_amount": this.getOrderInfo.invoiceInfo.templateInfo.template_data.total_price,
            net_amount:
              parseFloat(
                this.getOrderInfo.subscriptionInfo.templateInfo.template_data
                  .total_price
              ) *
                (tax / 100) +
              parseFloat(
                this.getOrderInfo.subscriptionInfo.templateInfo.template_data
                  .total_price
              ),
            net_amount_currency:
              Math.round(
                (parseFloat(
                  this.getOrderInfo.invoiceInfo.templateInfo.template_data
                    .total_price_currency
                ) +
                  Number.EPSILON) *
                  100
              ) / 100,
            // this.getOrderInfo.invoiceInfo.templateInfo.template_data.total_price_currency,
          },
          entity_data_id: "",
          template_id: "63bbd0e73a9034d3f041c373", //payment Template
          entity_id: "63bbd0f33a9034d3f041c3d2", //payments entity
          type: "PAYMENT",
        };
        this.subscriptionData = desc;
        this.selectedCurrency =
          this.getOrderInfo.invoiceInfo.templateInfo.template_data.total_price_currency;
        this.redirectionPath =
          AppDetails.PAYMENT_REDIRECTION_URL +
          "/payments-status?pgid=" +
          this.pgAccountId +
          "&txnAcId=" +
          info._id;
        if (info.pgType === "STRIPE") {
          this.dialogVisible = true;
          this.txnAcId = info._id;
          setTimeout(() => {
            this.loading = false;
          }, 3000);
        } else if (info.pgType === "SWIREPAY") {
          pay(
            decryptedData.apiKey,
            Math.ceil(this.items.amount * 100),
            0,
            this.selectedCurrency,
            "Esigns Subscription",
            this.redirectionPath,
            "test",
            ["CARD", "ACH_LEGACY"],
            desc,
            this.customer
          );
        }
      }
      this.loading = false;
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.selectedPlan.billingInfo = this.ruleForm;
          setTimeout(() => {
            this.loading = false;
            this.activeStep = this.activeStep + 1;
          }, 2000);
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    flipCard(status) {
      this.isCardFlipped = status;
    },
    focusInput(e) {
      this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      this.focusElementStyle = {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
      };
    },
    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    },
    async builderStep(step) {
      if (step == 1 && step < this.activeStep) {
        this.activeStep = step;
        this.step1 = "process";
        this.step2 = "wait";
        this.step3 = "wait";
      }
      if (step == 2 && step < this.activeStep) {
        this.activeStep = step;
        this.step1 = "success";
        this.step2 = "process";
        this.step3 = "wait";
      }
    },
    async subscribe(plan) {
      this.loading = true;
      this.selectedPlan.plan = plan;
      setTimeout(() => {
        this.loading = false;
        this.changeTerm();
        this.step1 = "success";
        this.step2 = "process";
        this.ste3 = "wait";
        this.activeStep = this.activeStep + 1;
      }, 2000);
    },
    validateCard() {
      this.$refs.elementRef.submit();
      // this will trigger the process
    },
  },
};
</script>
<style lang="scss" scoped>
.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;

  @media screen and (max-width: 576px) {
    margin: 0 auto;
  }

  &__inner {
    background: #fff;
    // box-shadow: 3px 13px 30px 0px rgba(21, 34, 67, 0.2);
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 10px;
    padding: 35px;
    padding-top: 180px;

    @media screen and (max-width: 480px) {
      padding: 25px;
      padding-top: 165px;
    }

    @media screen and (max-width: 360px) {
      padding: 15px;
      padding-top: 165px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;

    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: auto;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0;
      flex: unset;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.-cvv {
      max-width: 150px;

      @media screen and (max-width: 480px) {
        max-width: initial;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    height: 55px;
    background: #2364d2;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }
}

.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;

  @media screen and (max-width: 480px) {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }

  @media screen and (max-width: 360px) {
    height: 180px;
  }

  &.-active {
    .card-item__side {
      &.-front {
        transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
          rotateZ(0deg);
      }

      &.-back {
        transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
        // box-shadow: 0 20px 50px 0 rgba(81, 88, 206, 0.65);
      }
    }
  }

  &__focus {
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.65);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(8, 20, 47);
      height: 100%;
      border-radius: 5px;
      filter: blur(25px);
      opacity: 0.5;
    }

    &.-active {
      opacity: 1;
    }
  }

  &__side {
    border-radius: 15px;
    overflow: hidden;
    // box-shadow: 3px 13px 30px 0px rgba(11, 19, 41, 0.5);
    box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
    transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
    transform-style: preserve-3d;
    transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    backface-visibility: hidden;
    height: 100%;

    &.-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
      z-index: 2;
      padding: 0;
      // background-color: #2364d2;
      // background-image: linear-gradient(
      //   43deg,
      //   #4158d0 0%,
      //   #8555c7 46%,
      //   #2364d2 100%
      // );
      height: 100%;

      .card-item__cover {
        transform: rotateY(-180deg);
      }
    }
  }

  &__bg {
    max-width: 100%;
    display: block;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__cover {
    height: 100%;
    background-color: #1c1d27;
    position: absolute;
    height: 100%;
    background-color: #1c1d27;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(6, 2, 29, 0.45);
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 10px;

    @media screen and (max-width: 480px) {
      margin-bottom: 25px;
    }

    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }

  &__chip {
    width: 60px;

    @media screen and (max-width: 480px) {
      width: 50px;
    }

    @media screen and (max-width: 360px) {
      width: 40px;
    }
  }

  &__type {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
    margin-left: auto;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 40px;
      max-width: 90px;
    }

    @media screen and (max-width: 360px) {
      height: 30px;
    }
  }

  &__typeImg {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
    object-position: top right;
  }

  &__info {
    color: #fff;
    width: 100%;
    max-width: calc(100% - 85px);
    padding: 10px 15px;
    font-weight: 500;
    display: block;

    cursor: pointer;

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  }

  &__holder {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 6px;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    font-family: "Source Code Pro", monospace;
    padding: 25px 15px;
    position: relative;
    z-index: 4;
    height: 100%;
    text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
    user-select: none;

    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
  }

  &__name {
    font-size: 18px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__nameItem {
    display: inline-block;
    min-width: 8px;
    position: relative;
  }

  &__number {
    font-weight: 500;
    line-height: 1;
    color: #fff;
    font-size: 27px;
    margin-bottom: 35px;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 21px;
      margin-bottom: 15px;
      padding: 10px 10px;
    }

    @media screen and (max-width: 360px) {
      font-size: 19px;
      margin-bottom: 10px;
      padding: 10px 10px;
    }
  }

  &__numberItem {
    width: 16px;
    display: inline-block;

    &.-active {
      width: 30px;
    }

    @media screen and (max-width: 480px) {
      width: 13px;

      &.-active {
        width: 16px;
      }
    }

    @media screen and (max-width: 360px) {
      width: 12px;

      &.-active {
        width: 8px;
      }
    }
  }

  &__content {
    color: #fff;
    display: flex;
    align-items: flex-start;
  }

  &__date {
    flex-wrap: wrap;
    font-size: 18px;
    margin-left: auto;
    padding: 10px;
    display: inline-flex;
    width: 80px;
    white-space: nowrap;
    flex-shrink: 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__dateItem {
    position: relative;

    span {
      width: 22px;
      display: inline-block;
    }
  }

  &__dateTitle {
    opacity: 0.7;
    font-size: 13px;
    padding-bottom: 6px;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }

  &__band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 50px;
    margin-top: 30px;
    position: relative;
    z-index: 2;

    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }

    @media screen and (max-width: 360px) {
      height: 40px;
      margin-top: 10px;
    }
  }

  &__cvv {
    text-align: right;
    position: relative;
    z-index: 2;
    padding: 15px;

    .card-item__type {
      opacity: 0.7;
    }

    @media screen and (max-width: 360px) {
      padding: 10px 15px;
    }
  }

  &__cvvTitle {
    padding-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }

  &__cvvBand {
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #1a3b5d;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

    @media screen and (max-width: 480px) {
      height: 40px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }
}

.card-list {
  margin-bottom: -130px;

  @media screen and (max-width: 480px) {
    margin-bottom: -120px;
  }
}

.card-input {
  margin-bottom: 20px;

  &__label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #1a3b5d;
    width: 100%;
    display: block;
    user-select: none;
  }

  &__input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: #1a3b5d;
    font-family: "Source Sans Pro", sans-serif;

    &:hover,
    &:focus {
      border-color: #3d9cff;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }

    &.-select {
      -webkit-appearance: none;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC");
      background-size: 12px;
      background-position: 90% center;
      background-repeat: no-repeat;
      padding-right: 30px;
    }
  }
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}

.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}

.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}

.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}

.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}

.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}

.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}

.clearfix {
  padding-bottom: 2rem;
}

.mr-1 {
  padding: 0.5rem !important;
  margin: 0.5rem !important;
}

.boxSize {
  max-height: 500px;
  min-height: 300px;
}

.productInfo {
  svg {
    color: seagreen;
    width: 1rem;
  }
}

.align-center {
  text-align: center !important;
}

.custom-control-label {
  margin-left: 0.5rem;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
}

.table {
  width: 100%;

  .pull-right {
    text-align: right;
  }

  .pull-left {
    text-align: left;
  }

  .strong {
    font-weight: 500;
  }
}

#featuresList {
  .inlineList {
    display: block;
    list-style-type: none;
    //padding-right: 20px;
  }
}
</style>

<style scoped>
.card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.card:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}
.active-card {
  border: 2px solid #0d6efd;
}
.feature-list {
  max-height: calc(60vh - 80px - 56px); /* Subtract header and footer heights */
  scrollbar-width: thin;
}
.sticky-footer {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 10px;
  border-top: 1px solid #ddd;
}
</style>
